import React from "react"

import BackgroundImage from "gatsby-background-image"

import {
  Typography,
  Box,
  Container,
  Link as StyleLink,
  Fab,
} from "@material-ui/core"
import { KeyboardArrowDown as ArrowDownIcon } from "@material-ui/icons"

import Animate from "src/components/animate"

const SectionCover = props => (
  <BackgroundImage
    fluid={props.fluid}
    style={{
      height: props.height,
    }}
  >
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      style={{ backgroundColor: props.overlay && "rgba(0, 0, 0, 0.6)" }}
      display="flex"
      flexDirection="column"
      textAlign="center"
      height="100%"
    >
      {!RegExp("top").test(props.position) && <Box flexGrow={1} />}

      <Container>
        <Animate>
          <Box
            marginY={4}
            style={{ color: "white", textShadow: "0 0 2px black" }}
            textAlign={
              (RegExp("left").test(props.position) && "left") ||
              (RegExp("center").test(props.position) && "center")
            }
          >
            {props.title && (
              <Box marginY={2}>
                <Typography variant="h4" variantMapping={{ h4: "h1" }}>
                  {props.title}
                </Typography>
              </Box>
            )}

            {props.subtitle && (
              <Box marginY={2}>
                <Typography variant="h5" variantMapping={{ h5: "h2" }}>
                  {props.subtitle}
                </Typography>
              </Box>
            )}
          </Box>
        </Animate>
      </Container>

      {!RegExp("bottom").test(props.position) && <Box flexGrow={1} />}

      {props.scroll && (
        <Box marginY={1} id={props.id}>
          <StyleLink href={`#${props.id}`}>
            <Fab color="primary" size="small" aria-label="scroll">
              <ArrowDownIcon />
            </Fab>
          </StyleLink>
        </Box>
      )}
    </Box>

    <Box
      position="absolute"
      bottom={0}
      right={0}
      marginRight={0.5}
      style={{
        color: "white",
        mixBlendMode: "difference",
      }}
    >
      <Typography variant="caption">
        <small>{props.caption}</small>
      </Typography>
    </Box>
  </BackgroundImage>
)

export default SectionCover
