import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "src/components/seo"
import SectionCover from "src/components/section-cover"
import SectionCard from "src/components/section-card"
import SectionGrid from "src/components/section-grid"

import translate from "src/functions/translate"

export const query = graphql`
  query ($id: ID!) {
    strapi {
      page(id: $id) {
        title_fr
        title_en
        description_fr
        description_en
        slug
        image {
          url
          file {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        sections {
          ... on Strapi_ComponentSectionCover {
            container {
              title_fr
              title_en
              height
              scroll
              anchor
            }
            cover {
              subtitle_fr
              subtitle_en
              caption_fr
              caption_en
              position
              overlay
              image {
                url
                file {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentSectionCard {
            container {
              title_fr
              title_en
              height
              scroll
              anchor
            }
            card {
              content {
                title_fr
                title_en
                subtitle_fr
                subtitle_en
                custom_fr
                custom_en
                alignment
              }
              media {
                position
                caption_fr
                caption_en
                image {
                  url
                  file {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              link {
                title_fr
                title_en
                anchor
                url
                page {
                  title_fr
                  title_en
                  description_fr
                  description_en
                  slug
                  image {
                    url
                    file {
                      childImageSharp {
                        fluid(maxWidth: 600) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentSectionGrid {
            container {
              title_fr
              title_en
              height
              scroll
              anchor
            }
            columns
            cards {
              id
              content {
                title_fr
                title_en
                subtitle_fr
                subtitle_en
                excerpt_fr
                excerpt_en
              }
              media {
                position
                height
                image {
                  url
                  file {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              link {
                title_fr
                title_en
                anchor
                url
                page {
                  title_fr
                  title_en
                  description_fr
                  description_en
                  slug
                  image {
                    url
                    file {
                      childImageSharp {
                        fluid(maxWidth: 600) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            links {
              id
              title_fr
              title_en
              icon
              url
            }
          }
        }
      }
    }
  }
`

const Page = ({ data, pageContext }) => {
  useEffect(() => {
    console.log("=== Page ===")
  })

  const { page } = data?.strapi
  console.log("strapi page", page)

  const { language } = pageContext

  return (
    <Fragment>
      <SEO
        title={translate(page, "title", language)}
        slug={"/" + page.slug}
        image={page.image?.file.childImageSharp.fixed.src}
        description={translate(page, "description", language)}
      />

      {page.sections.map((section, index) => (
        <Fragment key={index}>
          {section.cover && (
            <SectionCover
              id={"section-" + index}
              height={section.container?.height}
              scroll={section.container?.scroll}
              fluid={section.cover.image?.file?.childImageSharp.fluid}
              title={
                section.container &&
                translate(section.container, "title", language)
              }
              subtitle={translate(section.cover, "subtitle", language)}
              caption={translate(section.cover, "caption", language)}
              position={section.cover.position}
              overlay={section.cover.overlay}
            />
          )}

          {section.card && (
            <SectionCard
              id={"section-" + index}
              heading={
                section.container &&
                translate(section.container, "title", language)
              }
              anchor={section.container?.anchor}
              fluid={section.card.media?.image?.file?.childImageSharp.fluid}
              position={section.card.media?.position}
              caption={
                section.card.media &&
                translate(section.card.media, "caption", language)
              }
              title={
                section.card.content &&
                translate(section.card.content, "title", language)
              }
              subtitle={
                section.card.content &&
                translate(section.card.content, "subtitle", language)
              }
              custom={
                section.card.content &&
                translate(section.card.content, "custom", language)
              }
              alignment={section.card.content?.alignment}
              link={section.card.link}
            />
          )}

          {section.cards && (
            <SectionGrid
              id={"section-" + index}
              anchor={section.container?.anchor}
              heading={
                section.container &&
                translate(section.container, "title", language)
              }
              cards={section.cards}
              links={section.links}
            />
          )}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default Page
