import React from "react"

import { useI18next } from "gatsby-plugin-react-i18next"

import {
  Typography,
  Box,
  Grid,
  Container,
  IconButton,
  Link as StyleLink,
} from "@material-ui/core"

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
} from "@material-ui/icons"

import Animate from "src/components/animate"
import CardImage from "src/components/card-image"

import translate from "src/functions/translate"

const IconBlock = props => (
  <Animate animation="zoom-in" duration="0">
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton
        color="primary"
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={props.label}
      >
        {props.children}
      </IconButton>
      {props.label && (
        <StyleLink href={props.url} target="_blank" rel="noopener noreferrer">
          {props.label}
        </StyleLink>
      )}
    </Box>
  </Animate>
)

const SectionGrid = props => {
  const { language } = useI18next()

  return (
    <Container>
      <Box marginY={8}>
        <Box marginY={2} textAlign="center" color="secondary.main">
          <Typography variant="h4">{props.heading}</Typography>
        </Box>

        <Box marginY={6}>
          {props.cards && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
            >
              {props.cards.map(card => (
                <Grid item xs={12} sm={12 / 2} md={12 / 3} key={card.id}>
                  <CardImage
                    fluid={
                      card.media?.image?.file?.childImageSharp.fluid ||
                      card.link?.page?.image?.file?.childImageSharp.fluid
                    }
                    to={card.link?.page && "/" + card.link.page.slug}
                    anchor={card.link?.anchor}
                    url={card.link?.url}
                    button={
                      card.link && translate(card.link, "title", language)
                    }
                    title={
                      (card.content &&
                        translate(card.content, "title", language)) ||
                      (card.link?.page &&
                        translate(card.link.page, "title", language))
                    }
                    subtitle={
                      card.content &&
                      translate(card.content, "subtitle", language)
                    }
                    position={card.media?.position || "top"}
                    height={card.media?.height}
                    description={
                      (card.content &&
                        translate(card.content, "excerpt", language)) ||
                      (card.link?.page &&
                        translate(card.link.page, "description", language))
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {props.links && (
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              {props.links.map(link => (
                <Grid item key={link.id}>
                  <IconBlock url={link.url} label={translate(link, "title")}>
                    {link.icon === "facebook" && (
                      <FacebookIcon style={{ fontSize: "6rem" }} />
                    )}
                    {link.icon === "instagram" && (
                      <InstagramIcon style={{ fontSize: "6rem" }} />
                    )}
                    {link.icon === "linkedin" && (
                      <LinkedInIcon style={{ fontSize: "6rem" }} />
                    )}
                  </IconBlock>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default SectionGrid
