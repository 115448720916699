import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, title, slug, image }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        strapi {
          page(id: "1") {
            image {
              url
              file {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const siteOrigin = "https://www.caroline.yoga"
  const pageUrl = siteOrigin + slug + "/"

  const metaImage =
    siteOrigin +
    (image || data.strapi.page.image.file.childImageSharp.fixed.src)
  const metaDescription =
    (description && description + " | ") + data.site.siteMetadata.description

  const metaTitle = `${title} | ${data.site.siteMetadata.title}`
  const metaAuthor = data.site.siteMetadata.author

  return (
    <Fragment>
      {pageUrl && metaTitle && metaDescription && metaImage && metaAuthor && (
        <Helmet>
          {/* START : General tags */}
          <html lang={lang} />
          <title>{metaTitle}</title>
          <meta
            name="description"
            content={metaDescription.substring(0, 160 - 3) + "..."}
          />
          <link rel="canonical" href={pageUrl} />
          <link rel="image_src" href={metaImage} />
          {/* STOP : General tags */}

          {/* START : OpenGraph tags */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:title" content={metaTitle} />
          <meta
            property="og:description"
            content={metaDescription.substring(0, 200 - 3) + "..."}
          />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
          {/* STOP : OpenGraph tags */}

          {/* START : Twitter Card tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={metaTitle} />
          <meta
            name="twitter:description"
            content={metaDescription.substring(0, 200 - 3) + "..."}
          />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:creator" content={metaAuthor} />
          {/* STOP : Twitter Card tags */}

          {/* START : Facebook domain verification */}
          <meta
            name="facebook-domain-verification"
            content="ryjk1hyvns125r0n7xl6c0vgnvsz50"
          />
          {/* STOP : Facebook domain verification */}
        </Helmet>
      )}
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  slug: ``,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
