import React from "react"
// import React, { Fragment } from "react"

import { Link as RouterLink } from "gatsby-plugin-react-i18next"
import BackgroundImage from "gatsby-background-image"

// import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core"
// import { LocationOnTwoTone as LocationIcon } from "@material-ui/icons"

import Animate from "src/components/animate"

// const useStyles = makeStyles({
//   hover: {
//     transition: "transform .2s ease-in-out",
//     "&:hover": {
//       transform: "scale(1.05)",
//     },
//   },
// })

const CardImage = props => {
  // const classes = useStyles()

  // const HoverLink = props => {
  //   if (props.url) {
  //     return (
  //       <RouterLink to={props.url} aria-label={props.url}>
  //         <Box overflow="hidden">
  //           <Box className={classes.hover}>{props.children}</Box>
  //         </Box>
  //       </RouterLink>
  //     )
  //   } else {
  //     return <Fragment>{props.children}</Fragment>
  //   }
  // }

  return (
    <Animate>
      <Card variant="outlined">
        <Grid
          container
          direction={props.position === "right" ? "row-reverse" : "row"}
        >
          {props.fluid && (
            <Grid item xs={12} sm={props.position === "top" ? 12 : 6}>
              {/* <HoverLink url={props.url}> */}
              <BackgroundImage
                fluid={props.fluid}
                style={{
                  height: "100%",
                  minHeight: props.height || "25vh",
                }}
              >
                {props.caption && (
                  <Box
                    position="absolute"
                    bottom={0}
                    right={0}
                    marginRight={0.5}
                    style={{
                      color: "white",
                      mixBlendMode: "difference",
                    }}
                  >
                    <Typography variant="caption">
                      <small>{props.caption}</small>
                    </Typography>
                  </Box>
                )}
              </BackgroundImage>
              {/* </HoverLink> */}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={!props.fluid || props.position === "top" ? 12 : 6}
          >
            <CardContent>
              <Box
                textAlign="center"
                margin={{ xs: 0, sm: 1, md: 2, lg: 3, xl: 4 }}
              >
                {(props.title || props.subtitle) && (
                  <Box marginY={2}>
                    <Typography
                      variant="h5"
                      variantMapping={{ h5: "p" }}
                      noWrap
                    >
                      {props.title}
                    </Typography>
                    {props.subtitle && (
                      <Typography>{props.subtitle}</Typography>
                    )}
                  </Box>
                )}

                {props.description && (
                  <Box
                    marginY={2}
                    color="text.secondary"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "2",
                    }}
                  >
                    <Typography>{props.description}</Typography>
                  </Box>
                )}

                {props.children && (
                  <Box marginY={2} color="text.secondary">
                    {props.children}
                    {/* {props.maps && (
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<LocationIcon />}
                        href={props.maps}
                        target="_blank"
                        aria-label="voir la carte"
                      >
                        voir la carte
                      </Button>
                    )} */}
                  </Box>
                )}

                {props.button && (
                  <Box marginY={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      href={(props.anchor && "#" + props.anchor) || props.url}
                      target={props.url && "_blank"}
                      component={props.to ? RouterLink : Button}
                      to={props.to}
                      aria-label={props.button}
                    >
                      {props.button}
                    </Button>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Animate>
  )
}

export default CardImage
