import React from "react"

import ReactMarkdown from "react-markdown"

import { useI18next } from "gatsby-plugin-react-i18next"

import { Typography, Box, Container } from "@material-ui/core"

import CardImage from "src/components/card-image"
import translate from "src/functions/translate"

const SectionCard = props => {
  const { language } = useI18next()

  return (
    <Container>
      <Box id={props.anchor} marginY={8}>
        <Box marginY={2} textAlign="center" color="secondary.main">
          <Typography variant="h4">{props.heading}</Typography>
        </Box>

        <Box marginY={6}>
          <CardImage
            fluid={props.fluid}
            position={props.position}
            title={props.title}
            subtitle={props.subtitle}
            caption={props.caption}
            button={props.link && translate(props.link, "title", language)}
            anchor={props.link?.anchor}
            to={props.link?.page && "/" + props.link.page.slug}
            url={props.link?.url}
          >
            <Box textAlign={props.alignment || "center"}>
              <Typography component="div">
                <ReactMarkdown source={props.custom} />
              </Typography>
            </Box>
          </CardImage>
        </Box>
      </Box>
    </Container>
  )
}

export default SectionCard
